import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import Image from 'components/Basic/Image/Image';
import { FC, HTMLAttributes, ImgHTMLAttributes, ReactNode } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { BenefitItemType } from 'types/benefits';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

const benefitIconWidths = [50, 46, 52, 69];

const BenefitsItem: FC<
    BenefitItemType & { loading: ImgHTMLAttributes<HTMLImageElement>['loading']; itemOrder: number }
> = (props) => {
    const BenefitsComponent = (props: NativeProps & { url: string | null; children: ReactNode }) => {
        if (props.url !== null) {
            return (
                <ExtendedNextLink href={props.url} type="static" {...props}>
                    {props.children}
                </ExtendedNextLink>
            );
        }
        return <div {...props}>{props.children}</div>;
    };

    return (
        <BenefitsComponent
            className="flex w-1/2 flex-col items-center px-2 md:w-auto vl:mr-[10px] vl:flex-row [&_img]:max-h-[50px] [&_img]:max-w-[70px]"
            url={props.url}
        >
            {props.image && (
                <Image
                    src={props.image.url}
                    loading={props.loading}
                    alt={props.name.replace(/<[^>]+>/g, '')}
                    width={benefitIconWidths[props.itemOrder]}
                    height={49}
                />
            )}

            <div
                className="mt-[14px] text-center text-small leading-[19px] vl:ml-[14px] vl:mt-0"
                dangerouslySetInnerHTML={{ __html: props.name }}
            />
        </BenefitsComponent>
    );
};

export default BenefitsItem;
