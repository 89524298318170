import { ArrowIcon } from 'components/Basic/Icon/IconsSvg';
import { BannersSliderItem } from 'components/Blocks/Banners/BannersSliderItem';
import { getFirstImage } from 'connectors/image/Image';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import { useSliderItemsQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useGetVisibleSliderIndexes } from 'hooks/slider/useGetVisibleSliderIndexes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import twMerge from 'utils/twMerge';

const AUTOPLAY_PLUGIN = Autoplay({
    delay: 5000,
    stopOnInteraction: false,
    stopOnMouseEnter: true,
    rootNode: (emblaRoot) => emblaRoot.parentElement,
});

export const BannersSlider: FC = () => {
    const testIdentifier = 'blocks-banners';
    const [{ data, error }] = useSliderItemsQueryApi();
    useQueryError(error);
    const autoplayRef = useRef(AUTOPLAY_PLUGIN);
    const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false, loop: true }, [autoplayRef.current]);
    const [selectedIndex, setSelectedIndex] = useState(0);

    const scrollNext = useCallback(() => {
        if (embla === undefined) {
            return;
        }

        embla.scrollNext();
        autoplayRef.current.reset();
    }, [embla]);

    const scrollPrev = useCallback(() => {
        if (embla === undefined) {
            return;
        }

        embla.scrollPrev();
        autoplayRef.current.reset();
    }, [embla]);

    const scrollTo = useCallback(
        (index: number) => {
            if (embla === undefined) {
                return;
            }

            embla.scrollTo(index);
            autoplayRef.current.reset();
        },
        [embla],
    );

    const onSelect = useCallback(() => {
        if (!embla) {
            return;
        }

        setSelectedIndex(embla.selectedScrollSnap());
    }, [embla, setSelectedIndex]);

    useEffect(() => {
        if (!embla) {
            return;
        }
        onSelect();
        embla.on('select', onSelect);
    }, [embla, onSelect]);

    const visibleSliderIndexes = useGetVisibleSliderIndexes(embla, [0], true, data?.sliderItems.length);

    if (data?.sliderItems === undefined || (Array.isArray(data.sliderItems) && data.sliderItems.length === 0)) {
        return null;
    }

    return (
        <div className="relative">
            <div
                className="relative h-auto w-full overflow-hidden xl:h-[393px] xxl:h-[496px]"
                data-testid={testIdentifier}
                ref={viewportRef}
            >
                <div className="relative flex h-full w-full cursor-pointer">
                    {data.sliderItems.map((sliderItem, index) => (
                        <BannersSliderItem
                            key={sliderItem.uuid}
                            mobileImage={getFirstImage(sliderItem.mobileImages)?.url}
                            webImage={getFirstImage(sliderItem.webImages)?.url}
                            link={sliderItem.link}
                            isContentVisible={visibleSliderIndexes.includes(index)}
                            index={index}
                            gtmId={sliderItem.gtmId}
                        />
                    ))}
                </div>
                <div className="absolute bottom-[6px] left-1/2 flex -translate-x-1/2 justify-center lg:bottom-[1Opx] vl:bottom-[15px]">
                    {data.sliderItems.map((item, index) => (
                        <button
                            key={item.uuid}
                            onClick={() => scrollTo(index)}
                            className={twMerge(
                                twJoin([
                                    'mx-1 h-[3px] w-[34px] cursor-pointer rounded-[16px] border-0 bg-greyLight text-[0px] outline-none',
                                    'lg:w-[62px]',
                                    'mx-[5px] vl:h-1 vl:w-[100px]',
                                    index === selectedIndex && 'bg-primary',
                                ]),
                            )}
                        />
                    ))}
                </div>
            </div>

            <button
                className={twJoin([
                    'group absolute -left-[20px] top-1/2 z-above flex h-[42px] w-[42px] -translate-y-1/2 rotate-90 cursor-pointer items-center justify-center rounded-md border-[1px] border-greyLight bg-white outline-none',
                    'lg:left-3 lg:h-7 lg:w-7',
                    'vl:h-[42px] vl:w-[42px]',
                ])}
                onClick={scrollPrev}
            >
                <ArrowIcon
                    className={twJoin([
                        '!h-4 !w-4 !text-greyLight duration-200 ease-default group-hover:!text-base',
                        'lg:!h-[10px] lg:!w-[10px]',
                        'vl:!h-4 vl:!w-4',
                    ])}
                />
            </button>
            <button
                onClick={scrollNext}
                className={twJoin([
                    'group absolute -right-[20px] top-1/2 z-above flex h-[42px] w-[42px] -translate-y-1/2 -rotate-90 cursor-pointer items-center justify-center rounded-md border-[1px] border-greyLight bg-white outline-none',
                    'lg:right-3 lg:h-7 lg:w-7',
                    'vl:h-[42px] vl:w-[42px]',
                ])}
            >
                <ArrowIcon
                    className={twJoin([
                        '!h-4 !w-4 !text-greyLight duration-200 ease-default group-hover:!text-base',
                        'lg:!h-[10px] lg:!w-[10px]',
                        'vl:!h-4 vl:!w-4',
                    ])}
                />
            </button>
        </div>
    );
};

BannersSlider.displayName = 'BannersSlider';
