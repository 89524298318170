import Image from 'components/Basic/Image/Image';
import { ProductPrice } from 'components/Blocks/Product/Price/ProductPrice';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC } from 'react';
import { LastVisitedProductType } from 'types/product';
import { DiscountPrice } from 'components/Blocks/Product/Price/DiscountPrice';
import { onGtmProductClickEventHandler } from 'helpers/gtm/eventHandlers';
import { GtmListNameType } from 'types/gtm/enums';
import { ButtonsAction } from 'components/Blocks/Product/ButtonsAction';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { twJoin } from 'tailwind-merge';
import { ProductFlags } from '../ProductFlags';

type LastVisitedItemProps = {
    product: LastVisitedProductType;
    listIndex: number;
    isContentVisible: boolean;
};

export const LastVisitedItem: FC<LastVisitedItemProps> = ({ product, listIndex, isContentVisible }) => {
    const testIdentifier = 'blocks-product-lastvisiteditem-';
    const t = useTypedTranslationFunction();
    const { url } = useDomainConfig();
    const productHref = product.slug;

    return (
        <div
            className={twJoin(
                'w-[203px] min-w-[203px] pr-[17px] text-base hover:text-primary',
                'xl:w-1/6 xl:min-w-[calc(100%/6)]',
                'xxl:w-1/7 xxl:min-w-[calc(100%/7)]',
            )}
            data-testid={testIdentifier + product.catalogNumber}
            onClick={() => onGtmProductClickEventHandler(product, GtmListNameType.lastVisited, listIndex, url)}
        >
            <div className="relative flex h-full flex-col rounded-lg bg-creamWhite text-left">
                {isContentVisible && (
                    <>
                        <ProductFlags
                            isProductList
                            flags={product.flags}
                            discountPercent={product.price.discountPercent}
                        />
                        <ExtendedNextLink
                            type="product"
                            href={productHref}
                            className="cursor-pointer no-underline hover:underline"
                        >
                            <div
                                className="relative flex h-[145px] w-full items-center justify-center bg-[transparent] px-5 pb-[18px] pt-[15px] text-[0] [&_img]:mix-blend-multiply"
                                data-testid={testIdentifier + 'image'}
                            >
                                <Image
                                    src={product.image?.url}
                                    alt={product.name}
                                    title={product.name}
                                    width={120}
                                    height={120}
                                    className="max-h-[120px] max-w-[122px]"
                                />
                            </div>
                        </ExtendedNextLink>
                        <div className="flex flex-1 flex-col bg-[transparent] px-5 pb-5 no-underline">
                            <ButtonsAction product={product} />
                            <ExtendedNextLink
                                type="product"
                                href={productHref}
                                className="cursor-pointer no-underline hover:underline"
                            >
                                <h3
                                    className="mb-[5px] block h-[60px] overflow-hidden break-words text-littleSmall font-base leading-5 text-primary"
                                    data-testid={testIdentifier + 'name'}
                                >
                                    {product.name}
                                </h3>
                            </ExtendedNextLink>
                            <div className="flex">
                                <span className="mb-[10px] text-extraVerySmall font-semiBold text-base">
                                    {t('Item code')}: {product.catalogNumber}
                                </span>
                                {product.__typename === 'Variant' && (
                                    <div className="mb-[10px] ml-auto pl-1 text-extraVerySmall font-semiBold text-base">
                                        <strong>{product.variantsCount}</strong>&nbsp;
                                        {t('(0)[Variants];(1)[Variant];(2-inf)[Variants];', {
                                            postProcess: 'interval',
                                            count: product.variantsCount,
                                        })}
                                    </div>
                                )}
                            </div>
                            <div className="mb-[13px] mt-auto flex flex-col flex-nowrap justify-between">
                                <DiscountPrice {...product.price} smallVariant withGap />
                                <ProductPrice {...product.price} secondaryPrice="smallFont" />
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

LastVisitedItem.displayName = 'LastVisitedItem';
