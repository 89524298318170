import { useLastVisitedDetails } from 'connectors/products/Products';
import { useGtmSliderProductsListEvent } from 'hooks/gtm/productsList/useGtmSliderProductsListEvent';
import { FC, HTMLAttributes, useMemo } from 'react';
import { usePersistStore } from 'store/zustand/usePersistStore';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { GtmListNameType } from 'types/gtm/enums';
import { LastVisitedProductsSkeleton } from './LastVisitedProductsSkeleton';
import { Webline } from 'components/Layout/Webline/Webline';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import twMerge from 'utils/twMerge';
import { useComponentMount } from 'hooks/ui/useComponentMount';
import { LastVisitedSlider } from './LastVisitedSlider';

type NativeProps = ExtractNativePropsFromDefault<HTMLAttributes<HTMLDivElement>, never, 'className'>;

type LastVisitedProps = {
    excludeItemCatalogNumber?: string;
};

export const LastVisited: FC<LastVisitedProps & NativeProps> = ({ excludeItemCatalogNumber, className }) => {
    const isComponentMount = useComponentMount();
    const t = useTypedTranslationFunction();
    const lastVisitedProducts = usePersistStore((s) => s.lastVisitedProducts);
    const lastVisitedProductsChecked = useMemo(() => {
        return excludeItemCatalogNumber !== undefined
            ? lastVisitedProducts.filter(
                  (lastVisitedItemCatalogNumber: string) => lastVisitedItemCatalogNumber !== excludeItemCatalogNumber,
              )
            : lastVisitedProducts;
    }, [excludeItemCatalogNumber, lastVisitedProducts]);
    const { lastVisitedProductsDetails, fetching } = useLastVisitedDetails(lastVisitedProductsChecked);

    useGtmSliderProductsListEvent(lastVisitedProductsDetails, GtmListNameType.lastVisited);

    if ((lastVisitedProductsDetails.length === 0 && !fetching) || !isComponentMount) {
        return null;
    }

    return (
        <Webline>
            <h3 data-testid="basic-heading-h3" className={twMerge(className, 'mb-0')}>
                <span id="LastVisitedProductsId">{t('Last visited products')}</span>
            </h3>

            {fetching ? <LastVisitedProductsSkeleton /> : <LastVisitedSlider products={lastVisitedProductsDetails} />}
        </Webline>
    );
};

LastVisited.displayName = 'LastVisited';
