import { twJoin } from 'tailwind-merge';
import { BannersSlider } from './BannersSlider';
import Trans from 'next-translate/Trans';
import { FC } from 'react';
import { Adverts } from '../Adverts/Adverts';

export const Banners: FC = () => {
    return (
        <>
            <div
                className={twJoin(
                    'mb-[26px] text-center text-default leading-7 text-primary',
                    'lg:text-[22px] lg:leading-8',
                    'xl:text-h1',
                )}
            >
                <Trans
                    i18nKey="Claim"
                    defaultTrans="We will equip your company within <1>24 hours</1>"
                    components={{
                        1: (
                            <strong className="relative after:absolute after:bottom-0 after:left-0 after:right-0 after:h-[3px] after:bg-orange after:content-[''] xl:after:h-[4px]" />
                        ),
                    }}
                />
            </div>

            <div className="mb-6 md:mb-[55px] md:grid md:grid-cols-[calc(67.7%-17px)_32.3%] md:gap-[17px] xl:grid-cols-[auto_383px] xxl:grid-cols-[auto_484px] xxl:gap-[21px]">
                <BannersSlider />
                <div
                    className={twJoin(
                        'flex flex-col',
                        'md:max-w-none md:[&>a:first-child]:mt-0 [&>a]:mt-3 [&>a]:rounded-lg md:[&>a]:mt-[2.5%] xl:[&>a]:mt-[17px] xxl:[&>a]:mt-[22px]',
                        'md:[&_img:first-child]:mt-0 [&_img]:!relative [&_img]:mt-3 [&_img]:rounded-2xl md:[&_img]:mt-[2.5%] md:[&_img]:max-h-[237px] md:[&_img]:max-w-[484px] xl:[&_img]:mt-[17px] xxl:[&_img]:mt-[22px]',
                        'md:[&>div:first-child]:mt-0 [&>div]:mt-3 [&>div]:rounded-lg md:[&>div]:mt-[2.5%] xl:[&>div]:mt-[17px] xxl:[&>div]:mt-[22px]',
                    )}
                >
                    <Adverts positionName="header1" loading="eager" imageSizes="(min-width: 600px) 484px, 100vw" />
                    <Adverts positionName="header2" loading="eager" imageSizes="(min-width: 600px) 484px, 100vw" />
                </div>
            </div>
        </>
    );
};

Banners.displayName = 'Banners';
