export const createEmptyArray = (length: number): null[] => Array(length).fill(null);

/**
 * If the value is in the array, remove it. Otherwise, add it.
 */
export const toggleValueInArray = (value: string, array: string[]): string[] => {
    if (array.includes(value)) {
        return array.filter((item) => item !== value);
    }
    return [...array, value];
};
