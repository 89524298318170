import { getFirstImage } from 'connectors/image/Image';
import { BenefitFragmentApi, useBenefitsQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { BenefitItemType } from 'types/benefits';

export const mapBenefitsApiData = (apiData: BenefitFragmentApi[]): BenefitItemType[] => {
    const mappedBenefitsItems = [];

    for (const benefitsItem of apiData) {
        mappedBenefitsItems.push({
            ...benefitsItem,
            image: getFirstImage(benefitsItem.image),
        });
    }

    return mappedBenefitsItems;
};

export function useBenefits(benefitsType: string): BenefitItemType[] | undefined {
    const [{ data, error }] = useBenefitsQueryApi({
        variables: { benefitType: benefitsType },
        pause: benefitsType === 'detail',
    });
    useQueryError(error);

    if (data?.benefits === undefined) {
        return undefined;
    }

    return mapBenefitsApiData(data.benefits);
}
