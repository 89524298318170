import { ArrowThinIcon } from 'components/Basic/Icon/IconsSvg';
import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel, { EmblaCarouselType } from 'embla-carousel-react';
import { useGetVisibleSliderIndexes } from 'hooks/slider/useGetVisibleSliderIndexes';
import { FC, memo, useCallback, useEffect, useState } from 'react';
import { LastVisitedProductType } from 'types/product';
import twMerge from 'utils/twMerge';
import { LastVisitedItem } from './LastVisitedItem';

type ProductsSliderProps = {
    products: LastVisitedProductType[];
};

const INITIAL_SLIDER_SETTINGS: EmblaOptionsType = {
    containScroll: 'trimSnaps',
    dragFree: false,
    duration: 20,
    startIndex: 0,
    align: 'start',
    breakpoints: {
        '(max-width: 599px)': { loop: false, skipSnaps: false },
    },
};

export const LastVisitedSlider: FC<ProductsSliderProps> = memo(({ products }) => {
    const [watchDrag, setWatchDrag] = useState(true);
    const [emblaRef, emblaApi] = useEmblaCarousel({ ...INITIAL_SLIDER_SETTINGS, watchDrag });
    const [prevBtnDisabled, setPrevButtonEnabled] = useState(true);
    const [nextBtnDisabled, setNextButtonEnabled] = useState(true);

    const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi]);
    const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi]);

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setPrevButtonEnabled(!emblaApi.canScrollPrev());
        setNextButtonEnabled(!emblaApi.canScrollNext());
    }, []);

    useEffect(() => {
        if (!emblaApi) {
            return void null;
        }

        onSelect(emblaApi);
        emblaApi.on('reInit', onSelect);
        emblaApi.on('select', onSelect);

        return () => {
            emblaApi.off('reInit', onSelect);
            emblaApi.off('select', onSelect);
        };
    }, [emblaApi, onSelect]);

    useEffect(() => {
        setWatchDrag(!(prevBtnDisabled && nextBtnDisabled));
    }, [prevBtnDisabled, nextBtnDisabled]);

    const visibleSliderIndexes = useGetVisibleSliderIndexes(emblaApi, [0, 1, 2, 3, 4, 5, 6]);

    return (
        <div className="relative">
            {(!prevBtnDisabled || !nextBtnDisabled) && (
                <div className="absolute right-0 top-1/3 z-above flex w-full items-center justify-center lg:top-[-42px] lg:w-auto">
                    <div
                        className={twMerge(
                            'absolute -left-5 flex h-[42px] w-[42px] cursor-pointer items-center justify-center rounded-md border border-greyLight bg-white text-creamWhite outline-none ui-transition',
                            'hover:bg-greyDark2',
                            'lg:static lg:ml-[5px] lg:border-greyVeryLight lg:bg-greyVeryLight',
                            prevBtnDisabled && 'cursor-default !bg-white hover:!bg-white [&_svg]:!text-greyLight',
                        )}
                        onClick={scrollPrev}
                    >
                        <ArrowThinIcon className="relative h-[18px] w-[18px] rotate-90 text-base" />
                    </div>
                    <div
                        className={twMerge(
                            'absolute -right-5 flex h-[42px] w-[42px] cursor-pointer items-center justify-center rounded-md border border-greyLight bg-white text-creamWhite outline-none ui-transition',
                            'hover:bg-greyDark2',
                            'lg:static lg:ml-[5px] lg:border-greyVeryLight lg:bg-greyVeryLight',
                            nextBtnDisabled && 'cursor-default !bg-white hover:!bg-white [&_svg]:!text-greyLight',
                        )}
                        onClick={scrollNext}
                    >
                        <ArrowThinIcon className="relative h-[18px] w-[18px] -rotate-90 text-base" />
                    </div>
                </div>
            )}

            <div
                className="relative -mx-5 mb-[45px] min-w-full overflow-hidden px-5 pt-[15px] xl:mx-0 xl:mb-[60px] xl:px-0 xl:py-[15px]"
                ref={emblaRef}
            >
                <div className="relative flex h-full xl:mr-[-17px]">
                    {products.map((productItemData, index) => (
                        <LastVisitedItem
                            product={productItemData}
                            listIndex={index}
                            key={productItemData.uuid}
                            isContentVisible={visibleSliderIndexes.includes(index)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
});

LastVisitedSlider.displayName = 'LastVisitedSlider';
