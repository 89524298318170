import { FC } from 'react';
import Image from 'components/Basic/Image/Image';

type BannerProps = {
    link: string;
    mobileImage?: string | null;
    webImage?: string | null;
    isContentVisible: boolean;
    index: number;
    gtmId: string;
};

export const BannersSliderItem: FC<BannerProps> = ({ gtmId, mobileImage, webImage, isContentVisible, link, index }) => {
    return (
        <a
            className="relative flex-[0_0_100%] flex-col [&_img]:rounded-2xl [&_img]:object-cover"
            href={link}
            id={gtmId}
        >
            {isContentVisible && (
                <>
                    <Image
                        className="h-full rounded-lg md:hidden"
                        src={mobileImage}
                        alt={`banner-item-${index}`}
                        loading="eager"
                        width={560}
                        height={350}
                        sizes="33vw"
                    />
                    <Image
                        className="hidden h-full rounded-lg md:inline"
                        src={webImage}
                        alt={`banner-item-${index}`}
                        loading="eager"
                        width={1015}
                        height={496}
                        sizes="50vw"
                    />
                </>
            )}
        </a>
    );
};

BannersSliderItem.displayName = 'BannersSliderItem';
