import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { Flag } from 'components/Basic/Flag/Flag';
import Image from 'components/Basic/Image/Image';
import { Button } from 'components/Forms/Button/Button';
import { isElementVisible } from 'components/Helpers/isElementVisible';
import { desktopFirstSizes } from 'components/Theme/mediaQueries';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { useGetWindowSize } from 'hooks/ui/UseGetWindowSize';
import { useResizeWidthEffect } from 'hooks/ui/UseResizeWidthEffect';
import { FC, Fragment, useState } from 'react';
import { ListedBlogArticleType } from 'types/blogArticle';

type BlogListProps = {
    blogListItems: ListedBlogArticleType[];
};

const TEST_IDENTIFIER = 'blocks-blogpreview-main-';

export const BlogList: FC<BlogListProps> = (props) => {
    const { width } = useGetWindowSize();
    const [isOneMainArticle, setOnlyOneMainArticle] = useState(false);
    const visibleArticles = isOneMainArticle ? 2 : 4;
    const t = useTypedTranslationFunction();

    useResizeWidthEffect(
        width,
        desktopFirstSizes.tablet,
        () => setOnlyOneMainArticle(false),
        () => setOnlyOneMainArticle(true),
        () => setOnlyOneMainArticle(isElementVisible([{ min: 0, max: desktopFirstSizes.tablet }], width)),
    );

    return (
        <>
            {props.blogListItems.map(
                (blogListItem, index) =>
                    index < visibleArticles && (
                        <div
                            className="flex w-full flex-col bg-creamWhite p-5"
                            key={index}
                            data-testid={TEST_IDENTIFIER + index}
                        >
                            <div className="relative flex w-full max-w-full">
                                <ExtendedNextLink
                                    href={blogListItem.link}
                                    type="blogArticle"
                                    className="relative mb-5 flex w-full"
                                >
                                    <Image
                                        className="h-auto max-h-[135px] w-full rounded-md md:max-h-[206px]"
                                        src={blogListItem.image?.url}
                                        alt={blogListItem.name}
                                        width={330}
                                        height={206}
                                        sizes="(min-width: 768px) 250px, (min-width: 1560px) 330px, 50vw"
                                    />
                                </ExtendedNextLink>
                                <div className="absolute left-0 top-0">
                                    {blogListItem.blogCategories.map((blogPreviewCategory, index) => (
                                        <Fragment key={index}>
                                            {blogPreviewCategory.parent !== null && (
                                                <Flag
                                                    color="#ffffff"
                                                    fullRadius
                                                    href={blogPreviewCategory.link}
                                                    hrefType="blogCategory"
                                                >
                                                    {blogPreviewCategory.name}
                                                </Flag>
                                            )}
                                        </Fragment>
                                    ))}
                                </div>
                            </div>
                            <div className="flex flex-1 flex-col">
                                <div className="mb-[2px] text-extraSmall text-grey">{blogListItem.publishDate}</div>
                                <ExtendedNextLink
                                    href={blogListItem.link}
                                    type="blogArticle"
                                    className="mb-[6px] block text-[15px] font-bold leading-5 no-underline hover:text-base hover:underline"
                                >
                                    {blogListItem.name}
                                </ExtendedNextLink>
                                <div className="flex-1 text-littleSmall leading-5 text-base">{blogListItem.perex}</div>
                                <ExtendedNextLink href={blogListItem.link} type="blogArticle" className="mt-2">
                                    <Button type="button" size="smallSecondary">
                                        {t('Read more')}
                                    </Button>
                                </ExtendedNextLink>
                            </div>
                        </div>
                    ),
            )}
        </>
    );
};

BlogList.displayName = 'BlogList';
