import { useWidgets } from 'connectors/widgets/Widgets';
import { FC, useEffect } from 'react';
import { twJoin } from 'tailwind-merge';
import { WidgetPlacementsEnumType } from 'types/widgets';

export const PromotedCategories: FC<{ className?: string }> = ({ className }) => {
    const { widgets, customStyles } = useWidgets([WidgetPlacementsEnumType.PROMO]);
    const htmlContent = widgets?.[WidgetPlacementsEnumType.PROMO].content;

    useEffect(() => {
        const toggleTrigger = document.querySelectorAll('[data-toggle-target]');

        toggleTrigger.forEach(function (item) {
            item.addEventListener('click', function () {
                const target = item.getAttribute('data-toggle-target');
                const content = document.querySelector('[data-toggle-content="' + target + '"]');
                item.classList.toggle('isActive');
                if (content !== null) {
                    content.classList.toggle('isActive');
                }
            });
        });

        if (toggleTrigger.length > 0) {
            window.addEventListener(
                'resize',
                function () {
                    if (this.window.outerWidth >= 769) {
                        document.querySelector('[data-toggle-content]')?.removeAttribute('style');
                    }
                },
                { passive: true },
            );
        }
    }, [htmlContent]);

    return (
        <div className={twJoin('mb-12', className)}>
            {htmlContent !== undefined && (
                <>
                    {customStyles !== null && <style>{customStyles}</style>}
                    <div className="promoted-categories" dangerouslySetInnerHTML={{ __html: htmlContent }} />
                </>
            )}
        </div>
    );
};

PromotedCategories.displayName = 'PromotedCategories';
