import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

type ProductItemSkeletonProps = {
    count: number;
    smallVariant?: boolean;
};

export const ProductItemSkeleton: FC<ProductItemSkeletonProps> = ({ count, smallVariant = false }) => {
    const imageSkeletonClass = smallVariant ? 'mb-6 h-32' : 'mb-6 h-32 md:mb-14 md:h-52';

    return (
        <>
            {Array(count)
                .fill(null)
                .map((_, i) => {
                    return (
                        <div className="flex w-full flex-col rounded border border-border p-2" key={i}>
                            <Skeleton className={imageSkeletonClass} />
                            <Skeleton className="mb-2 h-8" />
                            <Skeleton count={2} />
                            <Skeleton className="mb-4" />
                            <Skeleton className="h-14" />
                        </div>
                    );
                })}
        </>
    );
};
