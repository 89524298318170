import { ExtendedNextLink } from 'components/Basic/ExtendedNextLink/ExtendedNextLink';
import { BlogList } from './BlogList';
import { useBlogPreviewArticles } from 'connectors/articleInterface/blogArticle/BlogArticle';
import { useBlogUrl } from 'connectors/blogCategory/BlogCategory';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC } from 'react';
import { ChevronRightIcon } from 'components/Basic/Icon/IconsSvg';

export const BlogPreview: FC = () => {
    const testIdentifier = 'blocks-blogpreview';

    const t = useTypedTranslationFunction();

    const blogListItems = useBlogPreviewArticles();
    const blogUrl = useBlogUrl();

    if (blogListItems.length === 0) {
        return null;
    }

    return (
        <div className="pb-10 pt-[50px] vl:pb-[60px]" data-testid={testIdentifier}>
            <div className="mb-[10px] flex flex-wrap items-baseline justify-between">
                <h3>{t('Magazine')}</h3>
                {blogUrl !== undefined && (
                    <ExtendedNextLink
                        href={blogUrl}
                        type="blogCategory"
                        className="mb-[7px] flex items-center font-semiBold hover:text-base hover:no-underline"
                    >
                        <span>{t('View all')}</span>
                        <ChevronRightIcon className="relative top-[-1px] ml-[6px] text-extraSmall text-base" />
                    </ExtendedNextLink>
                )}
            </div>

            <div className="grid grid-cols-1 gap-[13px] sm:grid-cols-2 lg:grid-cols-4">
                <BlogList blogListItems={blogListItems} />
            </div>
        </div>
    );
};

BlogPreview.displayName = 'BlogPreview';
