import { ProductItemSkeleton } from 'components/Blocks/Product/List/ListedItem/ProductItemSkeleton';
import { createEmptyArray } from 'helpers/arrayUtils';
import { FC } from 'react';

export const LastVisitedProductsSkeleton: FC = () => {
    return (
        <div className="mb-[60px] mt-[15px] grid w-full grid-cols-2 gap-3 md:grid-cols-3 lg:grid-cols-4 vl:grid-cols-5 xl:grid-cols-6 xxl:grid-cols-7">
            {createEmptyArray(2).map((_, index) => (
                <ProductItemSkeleton count={1} key={index} smallVariant />
            ))}
        </div>
    );
};
