import { ProductsSlider } from 'components/Blocks/Product/ProductsSlider/ProductsSlider';
import { usePromotedProducts } from 'connectors/products/Products';
import { useGtmSliderProductsListEvent } from 'hooks/gtm/productsList/useGtmSliderProductsListEvent';
import { useTypedTranslationFunction } from 'hooks/typescript/UseTypedTranslationFunction';
import { FC, memo } from 'react';
import { GtmListNameType, GtmMessageOriginType } from 'types/gtm/enums';
import { ProductsSliderSkeleton } from './ProductsSlider/ProductsSliderSkeleton';

export const PromotedProducts: FC = memo(() => {
    const promotedProducts = usePromotedProducts();
    const t = useTypedTranslationFunction();
    useGtmSliderProductsListEvent(promotedProducts.products, GtmListNameType.homepageAction);

    if (promotedProducts.fetching) {
        return <ProductsSliderSkeleton count={5} />;
    }

    if (promotedProducts.products.length > 0) {
        return (
            <>
                <h3>{t('Action offer')}</h3>
                <ProductsSlider
                    products={promotedProducts.products}
                    gtmListName={GtmListNameType.homepageAction}
                    gtmMessageOrigin={GtmMessageOriginType.homepageAction}
                />
            </>
        );
    }

    return null;
});

PromotedProducts.displayName = 'PromotedProducts';
