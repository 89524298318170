import { SEARCH_QUERY_PARAMETER_NAME } from 'helpers/queryParams/queryParamNames';
import { useDomainConfig } from 'hooks/useDomainConfig';
import Head from 'next/head';
import { FC } from 'react';
import { getInternationalizedStaticUrls } from 'utils/getInternationalizedStaticUrls';

export const SearchMetadata: FC = () => {
    const { url } = useDomainConfig();
    const [searchUrl] = getInternationalizedStaticUrls(['/search/'], url);
    const searchUrlWithoutLeadingSlash = searchUrl.charAt(0) === '/' ? searchUrl.slice(1) : searchUrl;
    const absoluteUrl = url + searchUrlWithoutLeadingSlash;

    return (
        <Head>
            <script
                type="application/ld+json"
                id="search-metadata"
                dangerouslySetInnerHTML={{
                    __html: JSON.stringify({
                        '@context': 'https://schema.org',
                        '@type': 'WebSite',
                        url,
                        potentialAction: {
                            '@type': 'SearchAction',
                            target: {
                                '@type': 'EntryPoint',
                                urlTemplate: `${absoluteUrl}?${SEARCH_QUERY_PARAMETER_NAME}={q}`,
                            },
                            'query-input': 'required name=q',
                        },
                    }),
                }}
                key="search-metadata"
            />
        </Head>
    );
};
