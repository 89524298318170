import { BlogCategoryDetailFragmentApi, useBlogCategoriesApi, useSettingsQueryApi } from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { BlogCategoryDetailType, ListedBlogCategoryType } from 'types/blogCategory';

export const useBlogCategoryItems = (): { blogCategories: ListedBlogCategoryType[] | undefined; fetching: boolean } => {
    const [{ data, error, fetching }] = useBlogCategoriesApi();

    useQueryError(error);

    return { blogCategories: data?.blogCategories, fetching };
};

export const useBlogUrl = (): string | undefined => {
    const [{ data, error }] = useSettingsQueryApi();
    useQueryError(error);

    if (data?.settings?.mainBlogCategoryUrl !== undefined && data.settings.mainBlogCategoryUrl !== null) {
        return data.settings.mainBlogCategoryUrl;
    }

    return undefined;
};

export const mapBlogCategoryDetail = (apiData: BlogCategoryDetailFragmentApi): BlogCategoryDetailType => {
    return {
        ...apiData,
        __typename: 'BlogCategory',
    };
};
