import { AnchorHTMLAttributes, useState } from 'react';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import { ExtendedNextLink } from '../ExtendedNextLink/ExtendedNextLink';
import twMerge from 'utils/twMerge';
import tinycolor from 'tinycolor2';
import { FriendlyPagesTypesKeys } from 'types/friendlyUrl';

type NativeProps = ExtractNativePropsFromDefault<AnchorHTMLAttributes<HTMLAnchorElement>, 'href', never>;

type FlagProps = NativeProps & {
    color?: string;
    dataTestid?: string;
    backgroundColor?: string;
    fullRadius?: boolean;
    hrefType: FriendlyPagesTypesKeys;
};

export const Flag: FC<FlagProps> = ({
    backgroundColor: backgroundColorValue,
    dataTestid,
    href,
    color,
    fullRadius,
    children,
    hrefType,
}) => {
    const testIdentifier = dataTestid ?? 'basic-flag';
    const [backgroundColor, setBackgroundColor] = useState(backgroundColorValue ?? '#f19f26');

    const handleMouseOver = () => {
        setBackgroundColor(
            tinycolor(backgroundColorValue ?? '#f19f26')
                .darken(10)
                .toString(),
        );
    };

    const handleMouseOut = () => {
        setBackgroundColor(backgroundColorValue ?? '#f19f26');
    };

    return (
        <ExtendedNextLink
            href={href}
            type={hrefType}
            className={twMerge(
                'mb-[6px] mr-[6px] inline-flex rounded-bl-sm rounded-br-md rounded-tl-sm rounded-tr-md px-3 py-1 text-extraSmall font-bold uppercase leading-[14px] tracking-[0.24px] no-underline ui-transition',
                !fullRadius && 'rounded-bl-none rounded-tl-none',
                'hover:no-underline',
            )}
            data-testid={testIdentifier}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            style={{ color: color ?? '#fff', backgroundColor }}
        >
            {children}
        </ExtendedNextLink>
    );
};

Flag.displayName = 'Flag';
