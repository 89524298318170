import { SearchMetadata } from 'components/Basic/Head/SearchMetadata/SearchMetadata';
import { Banners } from 'components/Blocks/Banners/Banners';
import Benefits from 'components/Blocks/Benefits/Benefits';
import { BlogPreview } from 'components/Blocks/BlogPreview/BlogPreview';
import { PromotedCategories } from 'components/Blocks/Categories/PromotedCategories/PromotedCategories';
import CommonLayout from 'components/Layout/CommonLayout';
import { Webline } from 'components/Layout/Webline/Webline';
import {
    AdvertsQueryDocumentApi,
    BenefitsQueryDocumentApi,
    BlogArticlesQueryDocumentApi,
    SliderItemsQueryDocumentApi,
    WidgetsQueryDocumentApi,
} from 'graphql/generated';
import { useGtmStaticPageViewEvent } from 'helpers/gtm/eventFactories';
import { initServerSideProps, ServerSidePropsType } from 'helpers/InitServerSideProps';
import { useGtmPageViewEvent } from 'hooks/gtm/useGtmPageViewEvent';
import { FC } from 'react';
import { GtmPageType } from 'types/gtm/enums';
import { getServerSidePropsWithRedisClient } from 'helpers/misc/getServerSidePropsWithRedisClient';
import { PromotedProducts } from 'components/Blocks/Product/PromotedProducts';
import { LastVisited } from 'components/Blocks/Product/LastVisited/LastVisited';
import { Adverts } from 'components/Blocks/Adverts/Adverts';
import { WidgetPlacementsEnumType } from 'types/widgets';

const Index: FC<ServerSidePropsType> = () => {
    const pageViewEvent = useGtmStaticPageViewEvent(GtmPageType.home);
    useGtmPageViewEvent(pageViewEvent);

    return (
        <CommonLayout>
            <SearchMetadata />
            <Webline>
                <Banners />
            </Webline>
            <Webline containerClassName="flex flex-col">
                <Benefits className="order-2 md:order-none" benefitsType="title" loading="eager" />
                <PromotedCategories className="order-1 md:order-none" />
            </Webline>
            <Webline>
                <PromotedProducts />
                <div className="banners-action-offer">
                    <Adverts positionName="actionOffer1" secondImageBreakpoint="tablet" />
                    <Adverts positionName="actionOffer2" secondImageBreakpoint="tablet" />
                </div>
            </Webline>

            <Webline type="white">
                <BlogPreview />
            </Webline>

            <LastVisited />
        </CommonLayout>
    );
};

export const getServerSideProps = getServerSidePropsWithRedisClient((redisClient) => async (context) => {
    return initServerSideProps(context, redisClient, false, [
        { query: AdvertsQueryDocumentApi, variables: { positionName: 'actionOffer1' } },
        { query: AdvertsQueryDocumentApi, variables: { positionName: 'actionOffer2' } },
        { query: AdvertsQueryDocumentApi, variables: { positionName: 'header1' } },
        { query: AdvertsQueryDocumentApi, variables: { positionName: 'header2' } },
        { query: BenefitsQueryDocumentApi, variables: { benefitType: 'title' } },
        { query: BlogArticlesQueryDocumentApi, variables: { first: 4, onlyHomepageArticles: true } },
        { query: SliderItemsQueryDocumentApi },
        {
            query: WidgetsQueryDocumentApi,
            variables: {
                placements: [WidgetPlacementsEnumType.PROMO],
            },
        },
    ]);
});

export default Index;
