import BenefitsItem from './BenefitsItem/BenefitsItem';
import { useBenefits } from 'connectors/benefits/Benefits';
import { FC, ImgHTMLAttributes } from 'react';
import { BenefitItemType, BenefitsType } from 'types/benefits';
import twMerge from 'utils/twMerge';

type BenefitsProps = BenefitsType & {
    benefitsItems?: BenefitItemType[];
    className?: string;
    loading?: ImgHTMLAttributes<HTMLImageElement>['loading'];
};

const Benefits: FC<BenefitsProps> = ({ benefitsItems, benefitsType, className, loading }) => {
    const testIdentifier = 'benefits';
    const benefitsData = useBenefits(benefitsType);
    let benefitsItemsArray: Array<BenefitItemType> = [];

    if (benefitsType === 'title') {
        benefitsItemsArray = benefitsData ?? [];
    } else {
        benefitsItemsArray = benefitsItems ?? [];
    }

    if (Array.isArray(benefitsItemsArray) && benefitsItemsArray.length === 0) {
        return null;
    }

    return (
        <div
            className={twMerge(
                'mb-12 flex flex-wrap justify-evenly gap-y-[30px] md:items-center',
                benefitsType === 'detail' && 'mb-0 lg:mb-12',
                className,
            )}
        >
            {benefitsItemsArray.map((benefitItem, index) => {
                return (
                    <BenefitsItem
                        key={index}
                        image={benefitItem.image}
                        name={benefitItem.name}
                        data-testid={testIdentifier + '-item-' + index}
                        url={benefitItem.url}
                        loading={loading}
                        itemOrder={index}
                    />
                );
            })}
        </div>
    );
};

/* @component */
export default Benefits;
